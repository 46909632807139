import React from "react";
import "../../styles/components/Loader/style.scss";
function Loader() {
  return (
    <>
      <div id="preloader-active">
        <div className="preloader">
          <div className="preloader-inner position-relative">
            <div className="preloaderCircle" />
            <div className="preloaderImg">
              <img
                src="https://images.prismic.io/opalvest/9dbd1681-9157-4583-ada6-ead84f704ca8_login.png?auto=compress,format"
                className="preloadImg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
